:root {
  --brand-color: #17b978;
  /* Set your brand color */
}

/* Customize Bootstrap classes */
.btn-primary {
  background-color: var(--brand-color);
  border-color: var(--brand-color);
}

.btn-primary:disabled {
  background-color: #66f2ad;
  border-color: #66f2ad;
}

.btn-primary:hover {
  background-color: #008247;
  /* Lighter shade for hover effect */
  border-color: #008247;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  font-size: 8em;
  color: #1c339a;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-striped tbody tr.selected-row {
  --bs-table-bg: rgb(200, 200, 90);
  background-color: var(--bs-table-bg);
}

td {
  vertical-align: middle;
}

button.onsite-mobile {
  margin: 1em 0 1em;
  padding: 1em 0 1em;
  width: 100%;
}

.custom-hover:hover {
  background-color: #e3e3e5 !important;
}

.fir-form-row {
  display: flex;
  padding: 1em;
}

.mobile-friendly-modal-body {
  display: flex;
  flex-direction: column;
  height: 80vh;

  @media (max-width: 576px) {
    height: auto;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}
